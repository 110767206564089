import React from 'react'

function WorkExperience(props) {






  

  return (
    <div >
      <div className="projectsPage-container" id='PageTop'>
      <header>
            <img src="img/LogoWeb.png" alt="" />
            <h1 className='hidden'>LC Website Solutions</h1>
          </header>


        <main>
  
           
        <div className="cardItems">
                  <ul>   
                  <li>
                

                    <img src="img/Portfolio/journalPocket414.png" alt=''/>
                          <footer className='CardFooter-Container'>
                          <a href="https://journalpocket.com/">
                                  <button>View</button>
                                  </a>

                          </footer>
                                          
                    
                  </li>
                  <li>
                         
                         <img src="img/Portfolio/garageSally414.png" alt=''/>                          

                             <footer className='CardFooter-Container'>                                  
                             <a href="https://garagesallyapp.com/">
                               <button>View</button>
                               </a>



                         </footer>
                 </li>

                 <li>
                         
                         <img src="img/Portfolio/concordIron414.png" alt=''/>                          

                             <footer className='CardFooter-Container'>                                  
                             <a href="https://www.concordironworks.com/">
                               <button>View</button>
                               </a>



                         </footer>
                 </li>


  
                     
                   <li>

                       
                  <img src="img/Portfolio/ibUpholstery414.png" alt=''/>


                          <footer className='CardFooter-Container'>                                                                          
                          <a href="https://www.ibupholstery.com/">
                            <button>View</button>
                        </a>
                          </footer>
                  </li>   

                  <li>
                      
                      <img src="img/Portfolio/paceBuilders414.png" alt=''/>
                 
               

                              <footer className="CardFooter-Container">   
                                  <a href="https://buy.stripe.com/aEU6rFcPy7qj6xGeUV">                                                                       
                                  {/* <button  id='FreeWebsite'>Purchase Website</button>  */}
                                  </a>

                                  <a href="https://icy-flower-0ac939810.1.azurestaticapps.net">
                                    <button>View</button>
                                  </a>

                              </footer>
                      </li>

                        
                    <li>

                      <img src="img/Portfolio/theHookUp414.png" alt=''/>
                                  
                                  <footer className='CardFooter-Container'>                                 

                                  <a href="https://www.thehookup671islandbbq.com/">
                                  <button>View</button> 
                                  </a>

                              </footer>
                                              
                        
                      </li>

                         <li>

                        
                        <img src="img/Portfolio/ismaelRealtor414.png" alt=''/>
                     
                                    <footer className='CardFooter-Container'>                                 
                                    <a href="https://www.ismaelbanuelosrealtor.com/">
                                          <button>View</button>
                                        </a>
                               
                                </footer>
                                                
                          
                        </li>
                        <li>

                                                    
                            <img src="img/Portfolio/stMicheal414.png" alt=''/>

                                        <footer className='CardFooter-Container'>                                 

                                        <a href="https://www.stmichaeloakgrove.com/">
                                              <button>View</button>
                                            </a>

                                    </footer>
                                                    
                              
                            </li>

                        <li>

                   
                    <img src="img/Portfolio/delta414.png" alt=''/>
                           
                                <footer className='CardFooter-Container'>                                 

                                <a href="https://www.deltasportsbar.com/">
                                      <button>View</button>
                                    </a>

                            </footer>
                                            
                      
                    </li>


                        <li>

                     
                        <img src="img/Portfolio/jrProLandscaping414.png" alt=''/>
                     

                            <footer className='CardFooter-Container'>                                                                          
                                <a href="https://www.jrprolandscaping.com/">
                                  <button>View</button>
                              </a>
                            </footer>
                        </li>

                        

                      
               


                       






                  

                 
                    <li></li>
                  </ul>



          

                                          

                  </div>

                  
               

                  </main>

         
                  


                  </div>
           

                

    </div>
  )
}


export default WorkExperience
